import React from 'react'
import Helmet from 'react-helmet'
import { isNetlifyProduction } from '../utils/environment'

const Analytics = () => {
  return (
    <>
      {isNetlifyProduction && (
        <Helmet>
          <script defer data-domain="tumba.solutions" data-api="/pl/api/event" src="/pl/js/script.js"></script>
        </Helmet>
      )}
    </>
  )
}

export default Analytics
